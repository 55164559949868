<template>
  <div class="box">
    <div class="box-header">
        <div class="box-header-left"> 
            <div class="box-header-link" @click="page='home'" :style="{color:page=='home'?'rgba(255,255,255,1)':'rgba(255,255,255,0.6)'}">
            产品介绍
            </div>
            <div class="box-header-link" title="需要本产品进行翻墙即可打开" @click="page='google'" :style="{color:page=='google'?'rgba(255,255,255,1)':'rgba(255,255,255,0.6)'}">
              谷歌搜索
            </div>
            <div class="box-header-link" title="需要本产品进行翻墙即可打开" @click="page='youtube'" :style="{color:page=='youtube'?'rgba(255,255,255,1)':'rgba(255,255,255,0.6)'}">
              联系我们
            </div>
            <div class="box-header-link"> 
              <a class="box-header-link-a" :href="'https://inter.kuilld.com/#/register?'+this.code" target="_blank">免费体验</a>
            </div>
            <!-- <div class="box-header-link" title="需要本产品进行翻墙即可打开">
              <a class="box-header-link-a" href="https://www.91md.ws/" target="_blank">动作电影</a>
            </div> -->
        </div>
      <div class="box-header-cr">
        <span class="logo">Kuilld.com</span>
      </div>
    </div>

    <div class="box-content" v-if="page == 'home'">
      <div class="box-content-left">
        <h3 class="box-content-left-title">点击探索世界</h3>
        <div class="">各个系统均有教程，请点击你所用系统</div>
        <!-- <div class=""></div> -->

        <div class="d-but" @click="onChangePage('1')">
          <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg t="1723542275169" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4302" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20"><path d="M141.1 331.2c-35 0-63.3 28.3-63.3 63.2v264.7c0 34.9 28.4 63.2 63.3 63.2 35 0 63.3-28.3 63.3-63.2V394.4c0-34.9-28.3-63.2-63.3-63.2zM228.9 753.3c0 37.3 30.3 67.5 67.6 67.5h46.1v140c0 34.9 28.4 63.2 63.3 63.2 35 0 63.3-28.3 63.3-63.2v-140h85.5v140c0 34.9 28.4 63.2 63.3 63.2 35 0 63.3-28.3 63.3-63.2v-140h46.1c37.3 0 67.6-30.2 67.6-67.5V343.1H228.9v410.2zM651.8 93.4L696 13c2.4-4.2 0.8-9.6-3.5-11.9-4.2-2.3-9.6-0.8-11.9 3.5l-44.7 81.2C598.4 69.2 556.4 59.9 512 59.9c-44.4 0-86.4 9.3-123.9 25.8L343.4 4.6c-2.3-4.3-7.7-5.8-11.9-3.5-4.3 2.3-5.9 7.7-3.5 11.9l44.2 80.4c-86.8 44.8-145.5 130-145.5 227.7h570.5c0.1-97.8-58.6-182.9-145.4-227.7zM382.2 218c-13.2 0-23.9-10.7-23.9-23.9s10.7-23.9 23.9-23.9 23.9 10.7 23.9 23.9c-0.1 13.2-10.8 23.9-23.9 23.9z m259.7 0c-13.2 0-23.9-10.7-23.9-23.9s10.7-23.9 23.9-23.9 23.9 10.7 23.9 23.9c-0.1 13.2-10.8 23.9-23.9 23.9zM882.9 331.2c-35 0-63.3 28.3-63.3 63.2v264.7c0 34.9 28.4 63.2 63.3 63.2 35 0 63.3-28.3 63.3-63.2V394.4c0-34.9-28.3-63.2-63.3-63.2z" fill="#fff" p-id="4303"></path></svg>
           <span style="margin-left: 10px;">安卓手机</span>
        </div>
        <div class="d-but" @click="onChangePage('2')">
          <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg t="1723542757275" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5267" width="20" height="20" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M523.8 191.4v288.9h382V128.1zM523.8 833.6l382 62.2v-352h-382zM120.1 480.2H443V201.9l-322.9 53.5zM120.1 770.6L443 823.2V543.8H120.1z" p-id="5268" fill="#ffffff"></path></svg>
            <span style="margin-left: 10px;">windows</span>
        </div>
        <div class="d-but" @click="onChangePage('3')">
          <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg t="1723542924100" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6467" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20"><path d="M649.78554 161.505697C707.566573 88.168232 694.232489 8.163725 689.787794 1.496683c-6.667042-6.667042-93.338592 6.667042-153.341972 86.671549-53.336338 73.337465-53.336338 153.341972-46.669296 160.009014C496.443568 254.844289 583.115118 248.177247 649.78554 161.505697L649.78554 161.505697zM649.78554 161.505697" p-id="6468" fill="#ffffff"></path><path d="M794.238123 534.860064c-6.667042-106.672676 93.338592-173.343099 113.339719-186.677183l0-6.667042c0 0-86.671549-106.672676-213.345353-100.005634-80.004507 6.667042-120.006761 46.669296-173.343099 46.669296-66.670423 0-126.673803-46.669296-200.011268-46.669296-60.00338 0-233.346479 53.336338-246.680564 280.015775C63.085821 748.205417 223.094835 959.328422 283.098216 999.330676c60.00338 40.002254 100.005634 26.668169 166.676057-6.667042 33.335211-20.001127 140.007888-26.668169 200.011268 13.334085 73.337465 26.668169 180.010141 6.667042 300.016902-260.014648C934.24601 748.205417 800.905165 714.870205 794.238123 534.860064L794.238123 534.860064zM794.238123 534.860064" p-id="6469" fill="#ffffff"></path></svg>
            <span style="margin-left: 10px;">苹果手机</span>
        </div>
        <div class="d-but" @click="onChangePage('4')">
          <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg t="1723543124467" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7565" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20"><path d="M960 108.8c35.2 0 64 28.8 64 64v518.4c0 35.2-28.8 64-64 64H646.4l57.6 115.2c6.4 16 0 35.2-12.8 41.6-3.2 3.2-9.6 3.2-12.8 3.2H348.8c-19.2 0-32-12.8-32-32 0-6.4 0-9.6 3.2-12.8l57.6-115.2H64c-35.2 0-64-28.8-64-64V172.8c0-35.2 28.8-64 64-64h896zM598.4 294.4c-35.2-3.2-67.2 19.2-86.4 19.2-16 0-41.6-19.2-70.4-19.2-35.2 0-70.4 22.4-89.6 54.4-38.4 64-9.6 163.2 25.6 217.6l6.4 9.6c16 22.4 35.2 48 60.8 44.8 28.8 0 38.4-19.2 70.4-19.2 32 0 41.6 19.2 70.4 16 28.8 0 48-25.6 67.2-54.4s28.8-60.8 28.8-60.8h-3.2C665.6 496 624 473.6 624 416c0-54.4 44.8-80 48-83.2-19.2-32-57.6-38.4-73.6-38.4z m0-105.6c-22.4 0-48 16-64 32-12.8 16-25.6 41.6-22.4 67.2 25.6 0 48-12.8 64-32 12.8-16 25.6-41.6 22.4-67.2z" fill="#ffffff" p-id="7566"></path></svg>
            <span style="margin-left: 10px;">苹果电脑</span>
        </div>
        <p style="margin-top: 20px; font-size: 12px; line-height: 30px; text-align: center;" ><span class="logo">请耐心看完下方描述，我相信其中定有您想要的内容！</span></p>
      </div>
      <div class="box-content-item">
        <h3 class="box-content-h3">梯子、翻墙、科学上网是什么呢？</h3>
        <img width="100%" style="max-height: 400px;" src="./assets/gfw.png">
        <p class="box-content-text">防火长城（英语：Great Firewall，常用简称：GFW，中文也称中国国家防火墙），不让国民访问国外网络，害怕国民看见不该看的！本产品就是带着大家冲破防火墙，看外面的世界。</p>
        <p class="box-content-text" style="font-size: 16px;margin-top:10px; font-weight: 600;">带您解锁</p>
        <div class="box-content-itemBox">
          <div class="box-content-itemBox-item" v-for="(item, index) in jiesuo" :key="index">
            <h6 class="box-content-itemBox-item-title">{{ item.title }}</h6>
            <p class="box-content-itemBox-item-tes">
              {{ item.tes }}
            </p>
          </div>
        </div>
        <p class="box-content-text" style="color: red;" >提示别参与政治话题，别参与政治话题，别参与政治话题！！</p>
        <p class="box-content-text">外网自由度很高，从而骗子也很多，请小心甄别！ 提示大家外网软件别用国内手机号与邮箱注册因为不安全容易泄露数据给警方，别使用免费的梯子因为是官方钓鱼的，还有国产输入法也会泄露数据！</p>
        
      </div>
      
    </div>



    <div class="box-content" v-else-if="page == 'google'">
        <div class="box-content-google">
           <div class="box-content-google-logo">Google</div>
           <a class="box-content-google-input" href="https://www.google.com/" target="_blank">
            <div>在Google 中搜索， 请输入网站</div>
          </a>
            <a class="box-header-link-a" style="margin-top: 20px" href="https://www.91md.ws/" target="_blank">动作电影老司机点我</a>
           <div class="box-content-google-ps">使用google之前确保本产品已经下载并且连接</div>
           <div class="box-content-google-dow" @click="page='home'">下载 <span class="logo">Kuilld</span></div>
        </div>
    </div>

    <div class="box-content" style="padding: 0; overflow: hidden;" v-else-if="page == 'youtube'">
      <!-- <div class="box-content-google">
           <div class="box-content-google-logo" style="color: red;">YouTube</div>
           <a class="box-content-google-input" href="https://www.youtube.com/" target="_blank">
            <div>搜索你想看的视频</div>
          </a>
           <div class="box-content-google-ps">使用youtube之前确保本产品已经下载并且连接</div>
           <div class="box-content-google-dow" @click="page='home'">下载 <span class="logo">Kuilld</span></div>
        </div> -->
        <div class="iframBack" style="width: 100%; height: 100%; overflow-y: auto;">
          <div class="iframBack-Telegram">
            <div class="iframBack-Telegram-item">
              <a href="https://t.me/usernamelian" target="_blank"><svg t="1726717099242" class="icon" viewBox="0 0 1075 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="19650" width="48" height="48"><path d="M723.866 476.672c54.323 0 97.69 14.029 139.417 44.698l4.608 3.379a8.653 8.653 0 0 1-4.505 15.565l-2.407-0.154c-13.414-2.662-14.131-2.458-28.313-2.458a211.149 211.149 0 0 0-92.672 400.896H327.475a174.336 174.336 0 0 1-164.864-230.963l21.658-62.976a248.934 248.934 0 0 1 235.417-167.987h304.18z m112.179 110.08a161.69 161.69 0 1 1 0 323.277 161.69 161.69 0 0 1 0-323.328z m0 97.997l-63.59 63.641 63.59 63.642 63.641-63.642-63.641-63.641zM295.834 419.226l3.02 0.41a11.776 11.776 0 0 1 3.072 21.503c-62.105 37.376-103.936 73.677-125.542 108.8-22.477 36.557-37.94 70.861-46.438 102.912a17.408 17.408 0 0 1-14.644 12.8l-3.532 0.103-4.762-0.154a40.858 40.858 0 0 1-15.002-3.789c-45.824-17.408-72.192-65.075-60.16-111.77l2.1-7.014 11.622-33.74a133.478 133.478 0 0 1 117.248-89.805l8.96-0.308h124.058z m270.95-333.824a173.26 173.26 0 1 1 0 346.47 173.26 173.26 0 0 1 0-346.522zM256 209.408a92.928 92.928 0 1 1 0 185.856 92.928 92.928 0 0 1 0-185.856z" p-id="19651" fill="#1e6d64"></path></svg></a>
              <a href="https://t.me/usernamelian" target="_blank"><p class="iframBack-Telegram-item-text">产品代理</p></a>
              
            </div>
            <div class="iframBack-Telegram-item">
              <a href="https://t.me/+bxiWcL_pRg41Yzg1" target="_blank"><svg t="1726716847717" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16007" width="48" height="48"><path d="M658.17 717.322l73.452-347.08c6.497-30.612-11.01-42.568-31.045-35.068L268.599 501.951C239.09 513.427 239.602 530 263.606 537.5l110.515 34.555 256.62-161.777c12.002-8.014 23.044-3.494 14.018 4.52L437.172 602.569l-8.001 114.24a28.15 28.15 0 0 0 22.403-10.994l53.994-52.088 112.019 82.635c20.515 11.508 35.014 5.514 40.487-19.04h0.096zM961 512c0 247.976-201.024 449-449 449S63 759.976 63 512 264.024 63 512 63s449 201.024 449 449z" fill="#1e6d64" p-id="16008"></path></svg></a>
              <a href="https://t.me/+bxiWcL_pRg41Yzg1" target="_blank"><p  class="iframBack-Telegram-item-text">加入Kuilld社区群</p></a>
            </div>
            <div class="iframBack-Telegram-item">
              <a href="https://t.me/usernamelian" target="_blank"><svg t="1726716973986" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="18321" id="mx_n_1726716973987" width="48" height="48"><path d="M434.136615 309.917538l-85.858461 113.427693H197.277538v332.248615h629.76v-332.288h-141.154461L593.132308 311.729231a84.48 84.48 0 0 0-3.032616-67.190154A84.046769 84.046769 0 0 0 513.969231 196.923077a84.676923 84.676923 0 0 0-79.832616 112.994461z m139.027693 32.216616L634.092308 423.384615h-236.307693l59.313231-79.044923c15.005538 13.627077 34.973538 21.937231 56.871385 21.937231 23.04 0 43.913846-9.216 59.195077-24.142769zM512 1024C229.218462 1024 0 794.781538 0 512S229.218462 0 512 0s512 229.218462 512 512-229.218462 512-512 512zM288.452923 542.995692H343.04c12.603077 0 22.488615 2.835692 29.774769 8.664616 7.443692 6.025846 11.342769 14.887385 11.342769 26.584615a31.507692 31.507692 0 0 1-5.828923 18.825846 29.184 29.184 0 0 1-17.211077 10.988308v0.354461c12.051692 2.638769 18.786462 10.24 20.204308 23.000616l1.969231 19.534769a33.870769 33.870769 0 0 0 6.695385 18.589539h-15.753847a34.382769 34.382769 0 0 1-5.12-16.817231l-1.614769-16.856616c-0.708923-7.443692-3.387077-12.957538-7.955692-16.147692-4.292923-3.150769-10.279385-4.568615-18.274462-4.568615h-38.281846V669.538462h-14.532923v-126.54277z m119.808 0h89.678769v12.603077h-75.145846v42.338462h70.892308v12.603077h-70.892308v46.395077h78.178462V669.538462h-92.711385v-126.54277z m112.876308 0h15.241846l71.640615 102.951385h0.512v-102.951385h14.532923V669.538462h-14.887384l-71.955693-104.054154h-0.551384V669.538462h-14.532923v-126.54277z m117.681231 0h103.699692v12.603077h-44.662154V669.538462h-14.336v-113.979077H638.818462v-12.563693zM303.261538 555.323077v47.261538h43.52a23.512615 23.512615 0 0 0 23.433847-23.630769c0-12.681846-10.476308-23.630769-23.433847-23.630769H303.261538z" fill="#1e6d64" p-id="18322"></path></svg></a>
              
             <a href="https://t.me/usernamelian" target="_blank"><p  class="iframBack-Telegram-item-text">广告位招租</p></a> 
            </div>
            <div class="iframBack-Telegram-item">
              <a href="https://t.me/usernamelian" target="_blank"><svg t="1726715889704" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5224" width="50" height="50"><path d="M468.3 83.2c-198.7 19.6-360.5 178.2-384 376.5-14.3 120.8 21.9 232.7 89.6 318.5l-24.4 125.9c-2.6 13.3 10.2 24.4 23 20l120.8-41.6c78.7 46.5 173.1 69.3 273.8 56.6 197.9-25 355.3-187.6 373.7-386.2C965.9 281.8 739.6 56.5 468.3 83.2z m302.5 525.6c0 12-9.7 21.7-21.7 21.7h-80.5c-12 0-21.7-9.7-21.7-21.7V491.7c0-10.9 8.1-19.5 18.5-21.1-13.5-65.9-72-115.7-141.8-115.7-69.9 0-128.3 49.7-141.8 115.7 10.4 1.6 18.5 10.2 18.5 21.1v117.1c0 12-9.7 21.7-21.7 21.7H365c3 48.1 39.2 86.9 86.2 93.8 2.5-6.4 8.7-11 16.1-11H534c9.6 0 17.4 7.8 17.4 17.4V767c0 9.6-7.8 17.4-17.4 17.4h-67c-8 0-14.4-5.5-16.5-12.9-72.5-7.4-129.4-67.3-132.5-140.9h-20.2c-12 0-21.7-9.7-21.7-21.7V491.7c0-12 9.7-21.7 21.7-21.7h1.9C314.4 359.4 409 273.7 523.5 273.7s209.1 85.6 223.8 196.2h1.9c12 0 21.7 9.7 21.7 21.7v117.2z" fill="#1e6d64" p-id="5225"></path></svg></a>
              
             <a href="https://t.me/usernamelian" target="_blank"><p  class="iframBack-Telegram-item-text">客服</p></a> 
            </div>
          </div>
          <div class="iframBack-test">
            <h3  class="iframBack-test-h3">常见问题</h3>
            <div class="iframBack-test-item">该产品所有香港节点不支持Instagram 与 TikTok 注册登录， 请先使用美国节点并开启全局模式进行注册登录！ 小火箭开启方法点击全局路由勾选代理就是开启全局模式</div>
            <div class="iframBack-test-item">ChatGPT 香港节点也是无法访问，请切换日本或者美国节点</div>
            <div class="iframBack-test-item">小火箭默认没有开通自动更新， 所以每次开启vpn 请 <svg t="1726655771188" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4891" width="20" height="20"><path d="M185.856 457.216a42.496 42.496 0 0 0 55.296-27.648A272.896 272.896 0 0 1 375.808 271.36a268.288 268.288 0 0 1 204.8-13.312 293.888 293.888 0 0 1 126.464 91.136l-25.088-5.632a43.008 43.008 0 0 0-51.2 33.792 46.592 46.592 0 0 0 34.816 53.76l122.88 28.672a43.008 43.008 0 0 0 51.2-29.184l30.72-107.008a46.08 46.08 0 0 0-28.672-57.344 43.008 43.008 0 0 0-55.296 27.648l-2.048 13.312a385.536 385.536 0 0 0-177.152-133.12 353.792 353.792 0 0 0-271.872 17.408 358.4 358.4 0 0 0-178.176 209.92 46.08 46.08 0 0 0 28.672 55.808z m656.384 142.848a43.008 43.008 0 0 0-55.808 27.648 273.408 273.408 0 0 1-134.656 158.208 266.752 266.752 0 0 1-204.8 13.824 290.816 290.816 0 0 1-126.464-91.136l25.088 5.632a42.496 42.496 0 0 0 51.2-33.792 46.592 46.592 0 0 0-34.816-53.76l-122.88-28.672a42.496 42.496 0 0 0-51.2 29.184L153.6 733.696a46.592 46.592 0 0 0 28.672 57.344 42.496 42.496 0 0 0 55.296-27.648l3.584-11.776a387.584 387.584 0 0 0 177.152 133.12 354.816 354.816 0 0 0 272.384-17.408 361.984 361.984 0 0 0 179.712-209.92 46.592 46.592 0 0 0-28.16-57.344z" p-id="4892" fill="#ffffff"></path></svg> 进行更新节点</div>
            <div class="iframBack-test-item">香港、日本节点网速最快， 美国节点开放服务最多， 请优先使用香港日本节点， 还有其他问题请进社区群询问</div>
          </div>
          

          <!-- <iframe width="100%" height="100%" src="https://ylsp.tv" frameborder="0"></iframe> -->
        </div>
        
    </div>



    <div class="box-contentdetail" v-else-if="page == 'other'">
      <div @click="goback" class="box-contentdetail-goback">
          <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg t="1723613614073" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8627" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20"><path d="M943.2 899.9s-8.8 22.6-20.5 0c0 0-130.7-394.4-449.3-295.1v122.4s-5.1 72-67.9 25.1L92.4 481.9s-66.4-36.1 4-85.6l316.6-272s47.6-33.9 59.1 21.7l0.4 132.1c-0.1 0.1 593.7 28.6 470.7 621.8z" fill="#ffffff" p-id="8628"></path></svg>
          <span style="margin-left: 10px;">返回</span>
      </div>
      <div class="box-contentdetail-content">
        <h1 class="box-contentdetail-content-title"><span class="logo">Kuilld</span> 软件下载 <span>{{ type == '1' ? '- 安卓系统' : type == '2'?'- windows系统':type == '4'?'- mac系统':'' }}</span></h1>
        <div class="box-contentdetail-content-dInfo">
          <div class="box-contentdetail-content-dInfo-item">
              <p>4.8</p>
              <p style="margin-top: 5px;">评分</p>
            </div>
            <div  class="box-contentdetail-content-dInfo-item">
              <p>1万+</p>
              <p style="margin-top: 5px;">次下载</p>
            </div>
            <div  class="box-contentdetail-content-dInfo-item">
              <p>E</p>
              <p style="margin-top: 5px;">适合所有人</p>
            </div>
        </div>
        <div class="box-contentdetail-content-dow">
          <div class="box-contentdetail-content-dow-btn" @click="dow(1)" v-if="type !== '4'">下 载</div>
          <div class="box-contentdetail-content-dow-btn" @click="dow(1)" v-if="type == '4'">下载 Mac-arm</div>
          <div class="box-contentdetail-content-dow-btn" @click="dow(2)" v-if="type == '4'">下载 Mac-X64</div>
          <div class="box-contentdetail-content-dow-btn" @click="fenxiang">分 享</div>
        </div>
        <div class="box-contentdetail-content-test">
          <div v-if="type == '4'" style="width: 100%; color: #666666; font-size: 16px;margin-bottom: 10px;">ps: 如果Mac电脑提示 软件已损坏无法打开，你应该将它移到废纸篓，或着提升无法验证开发者请点击下方连接解决</div>
          <div v-if="type == '4'" style="width: 100%;margin-bottom: 20px;"><a  class="box-contentdetail-content-test-item-link" href="https://www.bilibili.com/read/cv33798336/" target="_blank" rel="noopener noreferrer">点击此链接-解决无法安装问题</a></div>
          
          <div v-if="type == '4'" class="box-contentdetail-content-test-item">
            <div class="box-contentdetail-content-test-item-title">
              软件已损坏
            </div>
            <div class="box-contentdetail-content-test-item-imgbox">
              <img  class="box-contentdetail-content-test-item-img" src="./assets/mac2.png" alt="">
            </div>
          </div>
          <div v-if="type == '4'" class="box-contentdetail-content-test-item">
            <div class="box-contentdetail-content-test-item-title">
              无法验证开发者
            </div>
            <div class="box-contentdetail-content-test-item-imgbox">
              <img  class="box-contentdetail-content-test-item-img" src="./assets/mac1.png" alt="">
            </div>
          </div>
          <div class="box-contentdetail-content-test-item">
            <div class="box-contentdetail-content-test-item-title">
               注册登录
            </div>
            <div class="box-contentdetail-content-test-item-imgbox">
              <img  class="box-contentdetail-content-test-item-img" src="./assets/1.png" alt="">
            </div>
          </div>
          <div class="box-contentdetail-content-test-item">
            <div class="box-contentdetail-content-test-item-title">
               购买订阅
            </div>
            <div class="box-contentdetail-content-test-item-imgbox">
              <img  class="box-contentdetail-content-test-item-img" src="./assets/2.png" alt="">
            </div>
          </div>
          <div class="box-contentdetail-content-test-item">
            <div class="box-contentdetail-content-test-item-title">
               手动节点选择
            </div>
            <div class="box-contentdetail-content-test-item-imgbox">
              <img  class="box-contentdetail-content-test-item-img" src="./assets/3.png" alt="">
            </div>
          </div>
          <div class="box-contentdetail-content-test-item">
            <div class="box-contentdetail-content-test-item-title">
               选择节点
            </div>
            <div class="box-contentdetail-content-test-item-imgbox">
              <img  class="box-contentdetail-content-test-item-img" src="./assets/4.png" alt="">
            </div>
          </div>
          <div class="box-contentdetail-content-test-item">
            <div class="box-contentdetail-content-test-item-title">
               连接节点
            </div>
            <div class="box-contentdetail-content-test-item-imgbox">
              <img  class="box-contentdetail-content-test-item-img" src="./assets/5.png" alt="">
            </div>
          </div>
          <div class="box-contentdetail-content-test-item">
            <div class="box-contentdetail-content-test-item-title">
               探索世界
            </div>
            <div class="box-contentdetail-content-test-item-imgbox">
              <img  class="box-contentdetail-content-test-item-img" src="./assets/six.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-contentdetail" v-else>
          <div @click="goback" class="box-contentdetail-goback">
              <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg t="1723613614073" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8627" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20"><path d="M943.2 899.9s-8.8 22.6-20.5 0c0 0-130.7-394.4-449.3-295.1v122.4s-5.1 72-67.9 25.1L92.4 481.9s-66.4-36.1 4-85.6l316.6-272s47.6-33.9 59.1 21.7l0.4 132.1c-0.1 0.1 593.7 28.6 470.7 621.8z" fill="#ffffff" p-id="8628"></path></svg>
              <span style="margin-left: 10px;">返回</span>
          </div>
          <div class="box-contentdetail-content">
              <h1 class="box-contentdetail-content-title"><span class="logo">Kuilld</span> iphone使用教程</h1>
              <div class="box-contentdetail-content-dInfo">
                <div class="box-contentdetail-content-dInfo-item">
                    <p>4.8</p>
                    <p style="margin-top: 5px;">评分</p>
                  </div>
                  <div  class="box-contentdetail-content-dInfo-item">
                    <p>1万+</p>
                    <p style="margin-top: 5px;">阅览</p>
                  </div>
                  <div  class="box-contentdetail-content-dInfo-item">
                    <p>E</p>
                    <p style="margin-top: 5px;">适合所有人</p>
                  </div>
              </div>
              <div class="box-contentdetail-content-test" style="width: 100%;">
                <div class="box-contentdetail-content-test-item" style="width: 100%;">
                  <div class="box-contentdetail-content-test-item-title">
                    更换美区id
                  </div>
                  <video class="video" controls>
                    <source src="./assets/test.mp4" type="video/mp4">
                      <source src="./assets/test.mp4" type="video/ogg">
                      Your browser does not support the video tag.
                  </video>
                </div>
                <div class="box-contentdetail-content-test-item-link" style="width: 100%;">
                  <a  class="box-contentdetail-content-test-item-link" href="https://www.meiguodizhi.com" target="_blank" rel="noopener noreferrer">点击此链接-打开美国地址生成器</a>
                  <div style="margin: 10px 0; color: #666666;">
                    ps: 跟换美区id后 下载Shadowrocket。 <a  class="box-contentdetail-content-test-item-link" href="https://www.bilibili.com/video/BV18r421t7Qw/?spm_id_from=333.880.my_history.page.click" target="_blank" rel="noopener noreferrer">如果视频卡顿可以点击点击此链接播放</a>
                  </div>
                  <a  class="box-contentdetail-content-test-item-link" :href="'https://inter.kuilld.com/#/register?'+this.code" target="_blank" rel="noopener noreferrer">点击此链接-打开官网</a>
                </div>
                <div class="box-contentdetail-content-test-item">
                  <div class="box-contentdetail-content-test-item-title">
                    打开官网注册登录
                  </div>
                  <div class="box-contentdetail-content-test-item-imgbox">
                    <img  class="box-contentdetail-content-test-item-img" src="./assets/os1.png" alt="">
                  </div>
                </div>
                <div class="box-contentdetail-content-test-item">
                  <div class="box-contentdetail-content-test-item-title">
                    购买订阅
                  </div>
                  <div class="box-contentdetail-content-test-item-imgbox">
                    <img  class="box-contentdetail-content-test-item-img" src="./assets/ios3.png" alt="">
                  </div>
                </div>
                <div class="box-contentdetail-content-test-item">
                  <div class="box-contentdetail-content-test-item-title">
                    一键订阅
                  </div>
                  <div class="box-contentdetail-content-test-item-imgbox">
                    <img  class="box-contentdetail-content-test-item-img" src="./assets/ios4.png" alt="">
                  </div>
                </div>
                <div class="box-contentdetail-content-test-item">
                  <div class="box-contentdetail-content-test-item-title">
                    导入Shadowrocket
                  </div>
                  <div class="box-contentdetail-content-test-item-imgbox">
                    <img  class="box-contentdetail-content-test-item-img" src="./assets/ios5.png" alt="">
                  </div>
                </div>
                <div class="box-contentdetail-content-test-item">
                  <div class="box-contentdetail-content-test-item-title">
                    选择节点开启vpn
                  </div>
                  <div class="box-contentdetail-content-test-item-imgbox">
                    <img  class="box-contentdetail-content-test-item-img" src="./assets/ios6.jpg" alt="">
                  </div>
                </div>
                <div class="box-contentdetail-content-test-item">
                  <div class="box-contentdetail-content-test-item-title">
                    探索世界
                  </div>
                  <div class="box-contentdetail-content-test-item-imgbox">
                    <img  class="box-contentdetail-content-test-item-img" src="./assets/six.png" alt="">
                  </div>
                </div>
              </div>
          </div>
          
    </div>
  </div>
</template>
<script>

export default {
  name: 'App',
  components: {
  },
  data(){
    return{
      page: 'home', // home other ios
      type: null, // 1==安卓 2==windows 3==ios 4==mac
      code: '',
      jiesuo:[
        {
          title: '小电影',
          tes: '国外色情影视合法'
        },
        {
          title: '电视剧、电影、动漫',
          tes: '外网看视频追剧无广告且全无删减'
        },
        {
          title: '推特寻找上门服务',
          tes: '推特上形成了一系列色情产业'
        },
        {
          title: '推特拉皮条必备',
          tes: '发布招嫖信息不但安全市场还大'
        },
        {
          title: '灰色产业信息发布',
          tes: '为了安全很多人都在外网寻找资源'
        },
        {
          title: '视频搬运',
          tes: '海外视频搬运回国，做好了比上班强'
        },
        {
          title: '亚马逊代购',
          tes: '亚马逊世界最大的电商平台'
        },
        {
          title: '海外电商',
          tes: '国外市场大，消费高赚钱容易'
        },
        {
          title: 'GPT海外影视解说',
          tes: '配合gpt进行电影解说'
        },
        {
          title: 'web3.0 概念',
          tes: '炒币做web3.0 概念投资'
        },
        {
          title: '看球赛',
          tes: '足球 NBA等'
        },
        {
          title: '推特 X',
          tes: '上面可以找到你想要的所有信息'
        },
        {
          title: 'Telegram 电报',
          tes: '绝对安全的聊天软件，雇佣兵杀手同款'
        },
        {
          title: 'instagram',
          tes: '追星必备，国内外明星必备软件'
        },
        {
          title: 'YouTube',
          tes: '世界上资源最多的播放器'
        },
        {
          title: 'TikTok',
          tes: '海外电商必备'
        },
        {
          title: '自媒体必备',
          tes: 'GPT，视频工具，配音等自媒体工具'
        },
        {
          title: 'Google',
          tes: '搜索，邮箱，安全密钥等'
        },
        {
          title: 'Google',
          tes: '搜索，邮箱，安全密钥等'
        },
        {
          title: '等等....',
          tes: '还有更多玩法挖掘，玩转信息差'
        },
      ]
    }
  },
  mounted(){
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    this.code = vars[0]
    console.log(this.code)
  },
  methods:{
    onChangePage(type){
      this.type = type
      if(type == '3'){
        this.page = 'ios'
      } else {
        this.page = 'other'
      }
    },
    goback(){
      this.page = 'home',
      this.type = null
    },
    fenxiang(){
      if(!navigator.share){
        alert('浏览器不支持分享功能')
      } else {
        navigator.share({
          title: window.location.title,
          url: window.location.href,
          text: '外网连接，探索世界'
        })
      }
    },
    dow(t){
      let url = ''
      if(this.type == '1'){
        url = 'files/Kuilld.apk'
      } else if (this.type == '2'){
        url = 'files/Kuilld.exe'
      } else {
        if(t == 1){
          url = 'files/Kuilld-arm64.dmg'
        } else {
          url = 'files/Kuilld.dmg'
        }
      }
      const a = document.createElement('a')
      a.style.display = "none";
      a.href = window.location.href+url;
      document.body.appendChild(a);
      a.click()
      document.body.removeChild(a)
    }
  }
}
</script>

<style>
@import url('./assets/testpc.css') screen and (min-width: 710px);
@import url('./assets/testos.css') screen and (max-width: 710px);
.box-content-google{
   display: flex;
   flex-direction: column;
   align-items: center ;
   width: 100%;
   height: 100%;

}
.box-content-google-logo{
  font-size: 80px;
  color: rgba(110, 81, 146, 1.00);;
  font-weight: 700;
  margin-top: 20vh;
}
.box-content-google-input{
  background-color: #fff;
  min-width: 310px;
  width: 90%;
  padding: 15px 30px;
  border-radius: 99px;
  max-width: 746px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 16px;
}

.box-content-google-ps{
  margin-top: 30px;
  font-size: 12px;
  color: rgba(255,255,255,0.8);
}

.box-content-google-dow{
  margin-top: 10px;
  font-size: 12px;
  color: rgba(255,255,255,0.5);
  cursor: pointer;
  text-decoration: underline;
}

.box-content-itemBox-item{
  display: inline-block;
  background-color: rgb(30, 109, 100);
  border-radius: 8px;
  padding: 4px 15px;
  margin: 5px; 
}
.box-content-itemBox-item-title{
  color: rgba(255, 255, 255, 0.9);
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}
.box-content-itemBox-item-tes{
  color: rgba(255, 255, 255, 1);
  font-size: 10px;
  margin-top: 2px;
}

.iframBack-test{
    width: 100%;
    max-width: 1200px;
    margin: auto;
    margin-top: 20px;
    padding: 20px;
}
.iframBack-test-h3{
    font-size: 20px;
    margin-bottom: 20px;
    color: rgba(255,255,255,0.8);
}
.iframBack-test-item{
    font-size: 16px;
    color: rgba(255,255,255,0.8);
    line-height: 22px;
    text-decoration:underline;
    margin-bottom: 10px;
}

.logo{
    font-weight: 700;
    background-image: linear-gradient(to right,rgba(251, 164, 72) ,rgba(224, 32, 39) ,rgba(0, 169, 227),rgba(232, 36, 132));
    background-clip: text;
    -webkit-background-clip: text; 
    -webkit-text-fill-color: transparent;
}

.iframBack{
  background-color: rgba(0,0,0,0.88);
  /* background-image: url('./assets/dongtu.gif'); */
  background-repeat: no-repeat; /* 防止背景图片重复 */
  background-position: center center; /* 水平垂直居中 */
  background-size: 220px;
}


html,body{height:100%;}
html,body,h1,h2,h3,h4,h5,h6,div,dl,dt,dd,ul,ol,li,p,blockquote,pre,hr,figure,table,caption,th,td,form,fieldset,legend,input,button,textarea,menu{margin:0;padding:0;}
header,footer,section,article,aside,nav,hgroup,address,figure,figcaption,menu,details{display:block;}
table{border-collapse:collapse;border-spacing:0;}
caption,th{text-align:left;font-weight:normal;}
html,body,fieldset,img,iframe,abbr{border:0;}
i,cite,em,var,address,dfn{font-style:normal;}
[hidefocus],summary{outline:0;}
li{list-style:none;}
h1,h2,h3,h4,h5,h6,small{font-size:100%;}
sup,sub{font-size:83%;}
pre,code,kbd,samp{font-family:inherit;}
q:before,q:after{content:none;}
textarea{overflow:auto;resize:none;}
label,summary{cursor:default;}
a,button{cursor:pointer;}
h1,h2,h3,h4,h5,h6,em,strong,b{font-weight:bold;}
del,ins,u,s,a,a:hover{text-decoration:none;}
body,textarea,input,button,select,keygen,legend{font:12px/1.14 Microsoft YaHei,arial,\5b8b\4f53;color:#333;outline:0;}
body{background:#fff;}
a,a:hover{color:#333;}
*{box-sizing: border-box;}
/* 滚动槽 */
div::-webkit-scrollbar {
    width: 0px;
    height: 6px;
}
div::-webkit-scrollbar-track {
    border-radius: 3px;
    background: rgba(0,0,0,0.06);
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.08);
}
/* 滚动条滑块 */
div::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: rgba(0,0,0,0.12);
    -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,0.2);
}
</style>
